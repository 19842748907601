@font-face {
  font-family: SatoshiRegular;
  font-display: swap;
  src: url("Satoshi-Regular.5cd7338f.woff2") format("woff2"), url("Satoshi-Regular.23214b96.woff") format("woff"), url("Satoshi-Regular.e855a0d3.ttf") format("truetype");
}

@font-face {
  font-family: SatoshiMedium;
  font-display: swap;
  src: url("Satoshi-Medium.5be63f72.woff2") format("woff2"), url("Satoshi-Medium.de33c0c3.woff") format("woff"), url("Satoshi-Medium.0cd9b7ce.ttf") format("truetype");
}

@font-face {
  font-family: SatoshiBold;
  font-display: swap;
  src: url("Satoshi-Bold.9042e65d.woff2") format("woff2"), url("Satoshi-Bold.d6123eb6.woff") format("woff"), url("Satoshi-Bold.642f978f.ttf") format("truetype");
}

@font-face {
  font-family: NeutrafaceBold;
  src: url("Neutra2Text-Bold.bf0aa66a.otf");
}

model-preview {
  --progress-bar-color: #04a8e0;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  background-color: var(--paper-color);
  color: var(--ink-color);
  font-size: 62.5%;
  position: relative;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: SatoshiRegular, sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
  position: relative;
}

#root {
  min-height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.svg-icon {
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

h1, h2, h3, h4, h5, h6 {
  font-family: SatoshiMedium, sans-serif;
  font-weight: 400;
}

.analyticsThumb {
  height: 50px;
}

.analyticsThumb:hover {
  transform: scale(2);
}

:root {
  --blue: #1f18c0;
  --red: #bd271a;
  --pink: #e83692;
  --yellow: #feca00;
  --ink: #00002a;
  --paper: #f6f4f1;
  --text: #44426d;
  --grey-text: #8e8cb0;
  --outline: #d0cfe6;
  --frost: #fcfbfc;
  --white: white;
  --font-size: 18px;
  --form-highlight: #465fcf;
  --quad: cubic-bezier(.455, .03, .515, .955);
}

::placeholder {
  color: var(--outline);
  opacity: 1;
}

input:hover {
  border-color: var(--grey-text) !important;
}

#light-root, .page {
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  background-color: var(--paper);
  position: absolute;
  top: 0;
  left: 0;
}

.flex {
  max-width: 1000px;
  flex-flow: wrap;
  justify-content: center;
  padding: 130px 0 120px;
  display: flex;
  position: relative;
  top: 0;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
}

.flex > * {
  margin: 10px;
  font-family: SatoshiRegular, sans-serif;
}

.navigation {
  width: 100%;
  height: 110px;
  background-color: var(--white);
  z-index: 1;
  border-bottom: 2px solid var(--outline);
  position: fixed;
  top: 0;
  left: 0;
}

.navigation-content {
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.gradient {
  width: 100%;
  height: 95px;
  background-color: var(--paper);
  box-shadow: 0 -20px 12px var(--paper);
  position: fixed;
  bottom: 0;
  left: 0;
}

.popup-parent {
  width: 100%;
  height: 100%;
  z-index: 3;
  background: #0003;
  position: fixed;
  top: 0;
  left: 0;
}

.popup {
  width: 300px;
  max-width: 95%;
  max-height: 95%;
  background: var(--white);
  box-sizing: border-box;
  overscroll-behavior: contain;
  border: 0;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.popup-title, .popup-text, .popup-p {
  box-sizing: border-box;
  width: 100%;
  color: var(--ink);
  pointer-events: none;
  font-family: SatoshiRegular, sans-serif;
  position: relative;
}

.popup-text {
  font-size: var(--font-size);
  text-align: center;
  padding: 60px 30px;
}

.popup-title {
  font-family: SatoshiMedium, sans-serif;
  font-size: var(--font-size);
  padding: 60px 30px 30px;
}

.popup-p {
  padding: 5px 30px;
  font-size: 16px;
}

.popup-img {
  margin: 0;
  padding: 0;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.popup-close {
  width: 20px;
  height: 20px;
  z-index: 2;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
}

.popup-close > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-button-container {
  width: 100%;
  height: 110px;
  margin: 0;
  padding: 0;
  position: relative;
}

.popup-cancel, .popup-confirm, .popup-cta {
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  border: 1px solid var(--blue);
  cursor: pointer;
  padding: 20px 30px;
  font-family: SatoshiRegular, sans-serif;
  position: absolute;
  bottom: 20px;
  left: 50%;
}

@media (hover: hover) and (pointer: fine) {
  .popup-cancel:hover, .popup-confirm:hover, .popup-cta:hover, .popup-close:hover, .cta-left:hover, .cta-right:hover, .remove-item-button:hover, .remove-logo-button:hover, #submit:hover {
    filter: brightness(1.2);
  }

  .menu-sub-button:hover {
    filter: brightness(.95);
  }

  .search-button:hover, .sort-dropdown-button:hover {
    background-color: var(--pink);
    border-color: var(--pink);
  }

  .page-button-prev:hover:enabled, .page-button-next:hover:enabled {
    background-color: var(--pink);
  }
}

.popup-cancel {
  background-color: var(--white);
  color: var(--blue);
  width: 120px;
  transform: translate(-105%);
}

.popup-confirm {
  background-color: var(--blue);
  color: var(--white);
  width: 120px;
  transform: translate(5%);
}

.popup-cta {
  background-color: var(--blue);
  color: var(--white);
  height: 67px;
  white-space: nowrap;
  transform: translate(-50%);
}

.vg-logo, .vg-logo-left {
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  padding: 20px 16px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.vg-logo-left {
  left: 0;
  transform: translate(0);
}

.navigation-title {
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  color: var(--text);
  max-width: 65%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translate(0, -50%);
}

.menu-closed, .menu-open {
  box-sizing: border-box;
  background-color: var(--ink);
  border: 0;
  border-radius: 20px;
  flex-direction: column;
  margin: 30px 20px;
  padding: 15px;
  transition: padding .1s linear;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.menu-closed {
  height: 50px;
  width: 50px;
  border-radius: calc(2 * var(--font-size));
  padding: 0;
}

.menu-sub-button {
  color: var(--white);
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  text-align: right;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  margin: 5px;
  padding: 0;
}

#submit {
  cursor: pointer;
}

.menu-sub-button > img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.menu-sub-button:disabled {
  color: var(--grey-text);
}

.signin-form {
  text-align: center;
  min-width: min(320px, 90vw);
  flex-direction: column;
  align-content: center;
  display: flex;
}

.password-parent {
  color: var(--ink);
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  border: 1px solid var(--outline);
  width: 100%;
  background-color: var(--white);
  margin: 5px 0;
  position: relative;
}

.signin-form > label, .signin-form > input, #submit-parent {
  color: var(--ink);
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  width: 100%;
  margin: 5px 0;
  padding: 15px 20px;
  position: relative;
}

.input-parent {
  margin: 5px 0;
  position: relative;
}

.input-parent > * {
  color: var(--ink);
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  width: 100%;
  margin: 0;
  padding: 15px 20px;
  position: relative;
}

.show-password-button-active, .show-password-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
}

.show-password-button-active {
  color: var(--grey-text);
}

.show-password-button {
  color: var(--outline);
}

.show-password-button-active:hover, .show-password-button:hover {
  color: var(--blue);
}

.signin-form > a {
  color: var(--grey-text);
  margin: 10px 0 0;
  padding: 5px 0 0;
  font-family: SatoshiRegular, sans-serif;
  font-size: .8em;
}

.signin-form > a:hover {
  color: var(--ink);
}

#password {
  width: calc(100% - 50px);
  color: var(--ink);
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  border: 1px solid #fff;
  padding: 15px 20px;
  position: relative;
  transform: translate(-25px);
}

#email, #firstname, #lastname, #company {
  border: 1px solid var(--outline);
}

#accept-terms-div {
  margin: 5px 0;
  padding: 15px 20px;
}

#accept-terms {
  margin: 0 15px 0 0;
}

.signup-success {
  text-align: center;
  padding: 0 40px;
}

.signup-success > * {
  color: var(--ink);
}

.anchor {
  color: var(--grey-text);
  padding: 5px;
  font-family: SatoshiRegular, sans-serif;
  font-size: .8em;
  text-decoration: underline;
}

.anchor:hover {
  color: var(--ink);
}

#submit-parent {
  width: 100%;
  height: 60px;
  padding: 0;
  overflow: hidden;
}

#submit {
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  color: var(--white);
  background-color: var(--blue);
  height: 100%;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

.width-container {
  width: 300px;
  position: relative;
}

.remove-item-button, .remove-logo-button {
  background-color: var(--red);
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  border: 0;
  border-radius: 30px;
  padding: 0;
  font-family: monospace;
  font-size: 25px;
  position: absolute;
}

.remove-logo-button {
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
}

.remove-item-button {
  width: 50px;
  height: 50px;
  top: 15px;
  right: 15px;
}

.remove-item-button > div, .remove-logo-button > div {
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.meter-label {
  font-size: var(--font-size);
  color: var(--grey-text);
  position: absolute;
  top: 10px;
  right: 20px;
}

.template-parent {
  max-width: 320px;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  top: 0;
  overflow: hidden;
}

.card-art, .card-intro, .card-template, .card-analytics, .card-drag-drop-half, .card-drag-drop {
  width: 300px;
  border: 1px solid var(--outline);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.card-intro, .card-template, .card-analytics, .card-drag-drop-half, .card-drag-drop {
  cursor: pointer;
}

.card-analytics-parent {
  min-width: 100%;
  min-height: 100%;
  height: 180px;
  color: var(--ink);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0 0 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.card-analytics-div {
  height: 32px;
  position: relative;
}

.card-analytics-text, .card-analytics-count {
  font-size: 1.2em;
  position: absolute;
  left: 50%;
}

.card-analytics-text {
  transform: translate(-20px);
}

.card-analytics-count {
  font-family: SatoshiBold, sans-serif;
  transform: translate(calc(-100% - 30px));
}

.card-intro > img, .card-template > img, .card-drag-drop > img, .card-art > img, .card-analytics > img, .card-drag-drop-half > img {
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-intro:hover, .card-template:hover, .card-drag-drop:hover, .card-drag-drop-half:hover, .card-analytics:hover {
  border-color: var(--grey-text);
}

.card-intro, .card-art, .card-analytics, .card-drag-drop {
  height: 180px;
}

.card-drag-drop-half {
  width: 150px;
  height: 90px;
  border: 1px solid var(--outline);
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.card-art {
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
}

.card-template {
  height: 120px;
  margin: 10px;
}

.card-drag-drop, .card-drag-drop-half {
  background-color: var(--frost);
}

.card-drag-drop > div, .card-drag-drop-half > div {
  width: 100%;
  height: 80%;
  font-size: var(--font-size);
  color: var(--grey-text);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-icon {
  width: 60px;
  height: 60px;
  border: 1px solid var(--outline);
  background-color: var(--white);
  color: var(--text);
  border-radius: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-icon > * {
  font-family: monospace;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cta-left, .cta-right {
  width: min(150px, 45vw);
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  border: 1px solid var(--blue);
  z-index: 2;
  cursor: pointer;
  margin: 0;
  padding: 20px 10px;
  font-family: SatoshiRegular, sans-serif;
  position: fixed;
  bottom: 20px;
  left: 50%;
}

.cta-left {
  background-color: var(--white);
  color: var(--blue);
  transform: translate(-105%);
}

.cta-right {
  background-color: var(--blue);
  color: var(--white);
  transform: translate(5%);
}

.pink {
  background-color: var(--pink);
  border-color: var(--pink);
  color: #fff;
}

.logo-component {
  height: 90px;
  margin: 0 0 20px;
  position: relative;
}

.logo-title {
  width: 50%;
  color: var(--ink);
  position: absolute;
  top: 0;
  left: 0;
}

.logo-description {
  top: calc(var(--font-size)  + 10px);
  width: 45%;
  color: var(--grey-text);
  font-size: .6em;
  position: absolute;
  left: 0;
}

.input-form {
  padding: 20px 0;
  position: relative;
}

.input-form > label {
  width: 100%;
  box-sizing: border-box;
  font-size: var(--font-size);
  color: var(--ink);
  padding: 10px 0;
  font-family: SatoshiRegular, sans-serif;
}

.input-form > input {
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  box-sizing: border-box;
  font-size: var(--font-size);
  width: 100%;
  margin: 10px 0 0;
  padding: 10px;
  font-family: SatoshiRegular, sans-serif;
}

.title-form {
  width: 70%;
  position: absolute;
  top: 15px;
  left: 15px;
}

.title-form > input {
  width: 95%;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  box-sizing: border-box;
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  padding: 10px 15px;
}

.slug-component {
  height: 65px;
  color: var(--ink);
  margin: 0 0 20px;
  position: relative;
}

.slug-info {
  background-color: var(--yellow);
  text-align: center;
  border-radius: 20px;
  padding: 10px 5px;
}

.slug-form {
  position: relative;
  transform: translate(0, -10px);
}

.slug-form > * {
  width: 50%;
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  padding: 0;
  display: inline-block;
  position: absolute;
}

.slug-form > label {
  color: var(--grey-text);
  font-size: .6em;
  top: 14px;
  left: 0;
}

.slug-form > input {
  box-sizing: border-box;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  padding: 5px;
  top: 0;
  right: 0;
}

.number-form {
  width: 100%;
  text-align: center;
  margin-top: 115px;
  position: relative;
}

.number-form > input {
  width: 95%;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  box-sizing: border-box;
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  text-align: center;
  padding: 10px 0;
}

.item-counter {
  z-index: 1;
  font-family: SatoshiRegular, sans-serif;
  font-size: var(--font-size);
  margin: 0;
  padding: 0;
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preview {
  width: 100%;
  height: calc(100% - 215px);
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 110px;
  left: 0;
}

.publish-container {
  height: calc(100% - 120px);
  width: 100%;
  position: absolute;
  top: 120px;
  overflow-x: hidden;
}

.publish-container > img, .publish-container > div {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.publish-container > img {
  width: 700px;
  top: 20px;
}

.publish-container > div {
  width: 80%;
  max-width: 400px;
  top: 140px;
}

.publish-title, .publish-subtitle, .publish-text, .creator-anchor {
  text-align: center;
  position: relative;
}

.publish-title {
  color: #fff;
  font-family: SatoshiBold, sans-serif;
  font-size: 120px;
}

.publish-subtitle {
  font-size: var(--font-size);
  color: #fff;
  margin: 0 0 20px;
  font-family: SatoshiMedium, sans-serif;
}

.publish-text, .creator-anchor {
  font-size: var(--font-size);
  color: #fff;
  font-family: SatoshiRegular, sans-serif;
}

.creator-anchor {
  cursor: pointer;
  text-decoration: underline;
}

.centered {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-card {
  width: 300px;
  height: 300px;
  border: 1px dashed var(--outline);
  border-radius: 20px;
  position: relative;
}

.loading-card-parent {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-component {
  width: 70px;
  height: 70px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner, .spinner-shadow {
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
  border: 6px solid;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  border-color: var(--ink) transparent transparent transparent;
  animation: spinner 1s infinite;
}

.spinner-shadow {
  border-color: #d6d6d6;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg)translate(-50%, -50%);
  }

  100% {
    transform: rotate(360deg)translate(-50%, -50%);
  }
}

.card-discover {
  width: 320px;
  height: 380px;
  cursor: pointer;
  transition: all .15s var(--quad);
  background-color: #fff6;
  border: 1px solid #00002a33;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.card-discover:hover {
  background-color: #fff;
  box-shadow: 0 0 2px -1px #a7a6ba, 0 0 2px #a7a6ba66, 0 0 24px -4px #a7a6ba99;
}

.card-discover-img {
  width: 300px;
  height: 180px;
  border-radius: 20px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 9px;
  left: 9px;
  overflow: hidden;
}

.card-discover-img > img {
  min-width: 300px;
  min-height: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-discover-content {
  height: 190px;
  width: 100%;
  color: var(--ink);
  margin: 0;
  padding: 0 10px 0 20px;
  position: absolute;
  top: 190px;
  left: 0;
}

.card-discover-title, .card-discover-description {
  letter-spacing: .01px;
  color: var(--ink);
  text-overflow: ellipsis;
  width: 100%;
  -webkit-box-orient: vertical;
  margin: 0;
  font-family: SatoshiMedium, sans-serif;
  font-size: 14px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.card-discover-title {
  max-height: 40px;
  -webkit-line-clamp: 2;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 19px;
}

.card-discover-description {
  max-height: 80px;
  -webkit-line-clamp: 4;
  font-family: SatoshiRegular, sans-serif;
  line-height: 18px;
}

.card-discover-date {
  height: 20px;
  letter-spacing: .025em;
  color: var(--ink);
  margin: 0;
  padding: 0 10px 0 20px;
  font-family: SatoshiRegular, sans-serif;
  font-size: 12px;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.nav-overlay {
  width: 100%;
  height: auto;
  z-index: 2;
  background-color: var(--frost);
  border-bottom: 1px solid var(--outline);
  position: relative;
  box-shadow: 0 0 2px -1px #a7a6ba, 0 0 2px #a7a6ba66, 0 0 24px -4px #a7a6ba99;
}

.nav-overlay-content {
  width: 100%;
  max-width: 1000px;
  height: auto;
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.discover-header {
  height: 110px;
  position: relative;
}

.discover-header > img {
  height: 50px;
  cursor: pointer;
  margin-right: 100px;
  position: relative;
  top: 20px;
  left: 20px;
}

.discover-more, .discover-signup {
  cursor: pointer;
  height: 50px;
  box-sizing: border-box;
  transition: all .15s var(--quad);
  font-family: SatoshiBold, sans-serif;
  font-size: 15px;
  position: absolute;
  top: 20px;
}

.discover-more {
  border: 1px solid var(--ink);
  background-color: var(--white);
  color: var(--ink);
  background: none;
  border: 0;
  padding: 10px 40px;
  right: 330px;
}

.discover-more > svg {
  width: 30px;
  height: 30px;
  background: none;
  position: absolute;
  top: 10px;
  right: 4px;
}

.discover-signup {
  max-width: 90%;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  border-radius: 25px;
  padding: 10px min(40px, 5vw);
  right: 20px;
}

.discover-signup:hover {
  filter: brightness(1.1);
  box-shadow: 0 0 2px -1px #a7a6ba, 0 0 2px #a7a6ba66, 0 0 24px -4px #a7a6ba99;
}

.discover-more:hover > svg {
  filter: brightness(1.1);
}

.discover-title {
  width: 100%;
  letter-spacing: -.05em;
  text-align: center;
  white-space: nowrap;
  color: var(--ink);
  margin: 0;
  padding: 0 20px;
  font-family: NeutrafaceBold, sans-serif;
  font-size: min(150px, 25vw);
  line-height: .8em;
  display: inline-block;
  position: relative;
  left: -5px;
}

.discover-title2 {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  color: var(--ink);
  margin: 0;
  padding: 0 20px;
  font-family: SatoshiMedium, sans-serif;
  font-size: min(30px, 5vw);
  display: inline-block;
  position: relative;
}

.search-parent {
  width: 540px;
  max-width: calc(100% - 40px);
  pointer-events: all;
  margin: 20px 0;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.search-parent > img {
  width: 20px;
  position: absolute;
  top: 15px;
  left: 20px;
}

.search-form {
  width: 100%;
  pointer-events: all;
  position: relative;
}

.search-form > input {
  width: 100%;
  height: 50px;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  box-sizing: border-box;
  letter-spacing: .025em;
  background: var(--white);
  padding: 10px 15px 10px 50px;
  font-family: SatoshiMedium, sans-serif;
  font-size: 18px;
}

.discover-page {
  flex-flow: wrap;
  display: flex;
  position: relative;
  overflow: hidden;
}

.discover-page > * {
  margin: 10px;
  font-family: SatoshiRegular, sans-serif;
}

.discover-actions {
  width: 100%;
  margin: 0;
  padding: 25px 0 15px;
  position: relative;
}

.search-term-parent {
  width: 100%;
  flex-direction: row;
  gap: 5px;
  padding: 0 20px 20px;
  display: flex;
  position: relative;
}

.search-term {
  height: 40px;
  color: var(--ink);
  box-sizing: border-box;
  width: fit-content;
  cursor: pointer;
  pointer-events: all;
  letter-spacing: .025em;
  background-color: #fff;
  border: 1px solid #44426d;
  border-radius: 25px;
  padding: 8px 40px 0 20px;
  font-family: SatoshiMedium, sans-serif;
  font-size: 14px;
  position: relative;
}

.search-term:hover {
  color: #1f18c0;
  border: 1px solid #1f18c0;
}

.search-term > svg {
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.top-button {
  width: 40px;
  height: 40px;
  border: 2px solid var(--outline);
  background-color: var(--white);
  color: var(--ink);
  cursor: pointer;
  border-radius: 40px;
  margin: 0;
  padding: 5px;
  position: fixed;
  bottom: 15px;
  left: 15px;
}

.top-button > img {
  transform: rotate(-90deg);
}

.card-discover-visit-button {
  border-radius: 25px !important;
}

.top-button:hover, .card-discover-visit-button:hover {
  filter: brightness(.95);
}

.exhibition-count {
  letter-spacing: .025em;
  color: var(--ink);
  font-family: SatoshiMedium, sans-serif;
  font-size: 18px;
  position: relative;
  bottom: 0;
  left: 30px;
}

.sortby-visitors-button, .sortby-date-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: var(--ink);
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  bottom: 13px;
}

.sortby-visitors-button {
  right: 70px;
}

.sortby-date-button {
  right: 30px;
}

.sortby-visitors-button:hover, .sortby-date-button:hover {
  color: #1f18c0;
}

.disabled {
  color: var(--grey-text);
}

.line {
  fill: none;
  color: currentColor;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.heart-filled {
  fill: var(--red);
  color: var(--red);
  stroke: var(--red);
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.path-filled {
  fill: currentColor;
}

/*# sourceMappingURL=index.9e7c87c2.css.map */
