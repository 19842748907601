:root {
  --blue: #1f18c0;
  --red: #bd271a;
  --pink: #e83692;
  --yellow: #feca00;
  --ink: #00002a;
  --paper: #f6f4f1;
  --text: #44426d;
  --grey-text: #8e8cb0;
  --outline: #d0cfe6;
  --frost: #fcfbfc;
  --white: white;
  --font-size: 18px;
  --form-highlight: #465fcf;
  --quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

::placeholder {
  color: var(--outline);
  opacity: 1;
}

input:hover {
  border-color: var(--grey-text) !important;
}

#light-root,
.page {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  background-color: var(--paper);
}

.flex {
  display: flex;
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  padding: 130px 0 120px 0;
  justify-content: center;
  max-width: 1000px;
}

.flex > * {
  font-family: 'SatoshiRegular', sans-serif;
  margin: 10px;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  background-color: var(--white);
  z-index: 1;
  border-bottom: 2px solid var(--outline);
}

.navigation-content {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding-left: 16px;
  padding-right: 16px;
  transform: translate(-50%);
}

.gradient {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 95px;
  background-color: var(--paper);
  box-shadow: 0 -20px 12px var(--paper);
}

.popup-parent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  max-width: 95%;
  max-height: 95%;
  transform: translate(-50%, -50%);
  background: var(--white);
  border: 0;
  border-radius: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  overscroll-behavior: contain;
}

.popup-title,
.popup-text,
.popup-p {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-family: 'SatoshiRegular', sans-serif;
  color: var(--ink);
  pointer-events: none;
}

.popup-text {
  padding: 60px 30px;
  font-size: var(--font-size);
  text-align: center;
}

.popup-title {
  padding: 60px 30px 30px 30px;
  font-family: 'SatoshiMedium', sans-serif;
  font-size: var(--font-size);
}

.popup-p {
  padding: 5px 30px;
  font-size: 16px;
}

.popup-img {
  position: relative;
  margin: 0;
  padding: 0;
  left: 50%;
  transform: translate(-50%);
}

.popup-close {
  position: absolute;
  background: transparent;
  border: 0;
  padding: 0;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 2;
  cursor: pointer;
}

.popup-close > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.popup-button-container {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 110px;
}

.popup-cancel,
.popup-confirm,
.popup-cta {
  position: absolute;
  bottom: 20px;
  left: 50%;
  font-size: var(--font-size);
  padding: 20px 30px;
  border-radius: calc(2 * var(--font-size));
  border: 1px solid var(--blue);
  font-family: 'SatoshiRegular', sans-serif;
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .popup-cancel:hover,
  .popup-confirm:hover,
  .popup-cta:hover,
  .popup-close:hover,
  .cta-left:hover,
  .cta-right:hover,
  .remove-item-button:hover,
  .remove-logo-button:hover,
  #submit:hover {
    filter: brightness(1.2);
  }

  .menu-sub-button:hover {
    filter: brightness(0.95);
  }

  .search-button:hover,
  .sort-dropdown-button:hover {
    background-color: var(--pink);
    border-color: var(--pink);
  }

  .page-button-prev:hover:enabled,
  .page-button-next:hover:enabled {
    background-color: var(--pink);
  }
}

.popup-cancel {
  transform: translate(-105%);
  background-color: var(--white);
  color: var(--blue);
  width: 120px;
}

.popup-confirm {
  transform: translate(5%);
  background-color: var(--blue);
  color: var(--white);
  width: 120px;
}

.popup-cta {
  transform: translate(-50%);
  background-color: var(--blue);
  color: var(--white);
  height: 67px;
  white-space: nowrap;
}

.vg-logo,
.vg-logo-left {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  height: 100%;
  margin: 0;
  padding: 20px 16px;
  box-sizing: border-box;
  cursor: pointer;
}

.vg-logo-left {
  left: 0;
  transform: translate(0);
}

.navigation-title {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translate(0, -50%);
  margin: 0;
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  color: var(--text);
  max-width: 65%;
}

.menu-closed,
.menu-open {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  box-sizing: border-box;
  border-radius: 20px;
  border: 0;
  background-color: var(--ink);
  margin: 30px 20px;
  padding: 15px;
  transition: padding 0.1s linear;
}

.menu-closed {
  height: 50px;
  width: 50px;
  padding: 0;
  border-radius: calc(2 * var(--font-size));
}

.menu-sub-button {
  margin: 5px;
  background-color: transparent;
  border: 0;
  padding: 0;
  color: var(--white);
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  text-align: right;
  cursor: pointer;
}

#submit {
  cursor: pointer;
}

.menu-sub-button > img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.menu-sub-button:disabled {
  color: var(--grey-text);
}

.signin-form {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  min-width: min(320px, 90vw);
}

.password-parent {
  position: relative;
  margin: 5px 0px;
  color: var(--ink);
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  border: 1px solid var(--outline);
  width: 100%;
  background-color: var(--white);
}

.signin-form > label,
.signin-form > input,
#submit-parent {
  position: relative;
  margin: 5px 0px;
  color: var(--ink);
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  padding: 15px 20px;
  width: 100%;
}

.input-parent {
  margin: 5px 0px;
  position: relative;
}

.input-parent > * {
  position: relative;
  margin: 0px;
  color: var(--ink);
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  padding: 15px 20px;
  width: 100%;
}

.show-password-button-active,
.show-password-button {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.show-password-button-active {
  color: var(--grey-text);
}
.show-password-button {
  color: var(--outline);
}
.show-password-button-active:hover,
.show-password-button:hover {
  color: var(--blue);
}

.signin-form > a {
  font-family: 'SatoshiRegular', sans-serif;
  font-size: 0.8em;
  color: var(--grey-text);
  padding: 5px 0 0 0;
  margin: 10px 0 0 0;
}

.signin-form > a:hover {
  color: var(--ink);
}

#password {
  width: calc(100% - 50px);
  transform: translate(-25px);
  position: relative;
  color: var(--ink);
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  border-radius: calc(2 * var(--font-size));
  padding: 15px 20px;
  border: 1px solid white;
}

#email,
#firstname,
#lastname,
#company {
  border: 1px solid var(--outline);
}

#accept-terms-div {
  margin: 5px 0px;
  padding: 15px 20px;
}

#accept-terms {
  margin: 0 15px 0 0;
}

.signup-success {
  padding: 0 40px;
  text-align: center;
}

.signup-success > * {
  color: var(--ink);
}

.anchor {
  color: var(--grey-text);
  padding: 5px;
  font-family: 'SatoshiRegular', sans-serif;
  font-size: 0.8em;
  text-decoration: underline;
}

.anchor:hover {
  color: var(--ink);
}

#submit-parent {
  padding: 0;
  width: 100%;
  height: 60px;
  overflow: hidden;
}

#submit {
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  color: var(--white);
  background-color: var(--blue);
  height: 100%;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

.width-container {
  position: relative;
  width: 300px;
}

.remove-item-button,
.remove-logo-button {
  position: absolute;
  background-color: var(--red);
  color: white;
  font-family: monospace;
  font-size: 25px;
  border: 0;
  border-radius: 30px;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.remove-logo-button {
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
}

.remove-item-button {
  top: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
}

.remove-item-button > div,
.remove-logo-button > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0;
  margin: 0;
}

.meter-label {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: var(--font-size);
  color: var(--grey-text);
}

.template-parent {
  display: flex;
  position: relative;
  top: 0;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0;
  margin: 0;
  max-width: 320px;
}

.card-art,
.card-intro,
.card-template,
.card-analytics,
.card-drag-drop-half,
.card-drag-drop {
  position: relative;
  width: 300px;
  border: 1px solid var(--outline);
  border-radius: 20px;
  overflow: hidden;
}

.card-intro,
.card-template,
.card-analytics,
.card-drag-drop-half,
.card-drag-drop {
  cursor: pointer;
}

.card-analytics-parent {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0 0 20px;
  display: flex;
  flex-direction: column;
  height: 180px;
  color: var(--ink);
  text-align: center;
}

.card-analytics-parent {
  justify-content: center;
}

.card-analytics-div {
  position: relative;
  height: 32px;
}

.card-analytics-text,
.card-analytics-count {
  position: absolute;
  left: 50%;
  font-size: 1.2em;
}

.card-analytics-text {
  transform: translate(-20px);
}

.card-analytics-count {
  font-family: 'SatoshiBold', sans-serif;
  transform: translate(calc(-100% - 30px));
}

.card-intro > img,
.card-template > img,
.card-drag-drop > img,
.card-art > img,
.card-analytics > img,
.card-drag-drop-half > img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
}

.card-intro:hover,
.card-template:hover,
.card-drag-drop:hover,
.card-drag-drop-half:hover,
.card-analytics:hover {
  border-color: var(--grey-text);
}

.card-intro,
.card-art,
.card-analytics,
.card-drag-drop {
  height: 180px;
}

.card-drag-drop-half {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 90px;
  overflow: hidden;
  border: 1px solid var(--outline);
  border-radius: 20px;
}

.card-art {
  display: flex;
  padding: 0 10px;
  box-sizing: border-box;
}

.card-template {
  height: 120px;
  margin: 10px;
}

.card-drag-drop,
.card-drag-drop-half {
  background-color: var(--frost);
}

.card-drag-drop > div,
.card-drag-drop-half > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 80%;
  font-size: var(--font-size);
  color: var(--grey-text);
  text-align: center;
}

.add-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border: 1px solid var(--outline);
  border-radius: 60px;
  background-color: var(--white);
  color: var(--text);
}

.add-icon > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  font-family: monospace;
}

.cta-left,
.cta-right {
  position: fixed;
  bottom: 20px;
  left: 50%;
  width: min(150px, 45vw);
  font-size: var(--font-size);
  padding: 20px 10px;
  margin: 0;
  border-radius: calc(2 * var(--font-size));
  border: 1px solid var(--blue);
  font-family: 'SatoshiRegular', sans-serif;
  z-index: 2;
  cursor: pointer;
}

.cta-left {
  transform: translate(-105%);
  background-color: var(--white);
  color: var(--blue);
}

.cta-right {
  transform: translate(5%);
  background-color: var(--blue);
  color: var(--white);
}

.pink {
  background-color: var(--pink);
  border-color: var(--pink);
  color: white;
}

.logo-component {
  position: relative;
  margin: 0 0 20px 0;
  height: 90px;
}

.logo-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  color: var(--ink);
}

.logo-description {
  position: absolute;
  top: calc(var(--font-size) + 10px);
  left: 0;
  width: 45%;
  font-size: 0.6em;
  color: var(--grey-text);
}

.input-form {
  position: relative;
  padding: 20px 0px;
}

.input-form > label {
  padding: 10px 0;
  width: 100%;
  box-sizing: border-box;
  font-size: var(--font-size);
  font-family: 'SatoshiRegular', sans-serif;
  color: var(--ink);
}

.input-form > input {
  padding: 10px 10px;
  margin: 10px 0 0 0;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  box-sizing: border-box;
  font-size: var(--font-size);
  font-family: 'SatoshiRegular', sans-serif;
  width: 100%;
}

.title-form {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 70%;
}

.title-form > input {
  width: 95%;
  padding: 10px 15px;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  box-sizing: border-box;
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
}

.slug-component {
  position: relative;
  margin: 0 0 20px 0;
  height: 65px;
  color: var(--ink);
}

.slug-info {
  background-color: var(--yellow);
  text-align: center;
  padding: 10px 5px;
  border-radius: 20px;
}

.slug-form {
  position: relative;
  transform: translate(0, -10px);
}

.slug-form > * {
  position: absolute;
  width: 50%;
  padding: 0;
  display: inline-block;
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
}

.slug-form > label {
  top: 14px;
  left: 0;
  font-size: 0.6em;
  color: var(--grey-text);
}

.slug-form > input {
  top: 0;
  right: 0;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
}

.number-form {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 115px;
}

.number-form > input {
  width: 95%;
  padding: 10px 0;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  box-sizing: border-box;
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  text-align: center;
}

.item-counter {
  z-index: 1;
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'SatoshiRegular', sans-serif;
  font-size: var(--font-size);
  margin: 0;
  padding: 0;
}

.preview {
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  height: calc(100% - 215px);
  margin: 0;
  padding: 0;
  border: 0;
}

.publish-container {
  position: absolute;
  top: 120px;
  height: calc(100% - 120px);
  width: 100%;
  overflow-x: hidden;
}

.publish-container > img,
.publish-container > div {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.publish-container > img {
  top: 20px;
  width: 700px;
}

.publish-container > div {
  top: 140px;
  width: 80%;
  max-width: 400px;
}

.publish-title,
.publish-subtitle,
.publish-text,
.creator-anchor {
  position: relative;
  text-align: center;
}

.publish-title {
  font-size: 120px;
  color: white;
  font-family: 'SatoshiBold', sans-serif;
}

.publish-subtitle {
  font-size: var(--font-size);
  margin: 0 0 20px 0;
  color: white;
  font-family: 'SatoshiMedium', sans-serif;
}

.publish-text,
.creator-anchor {
  font-size: var(--font-size);
  color: white;
  font-family: 'SatoshiRegular', sans-serif;
}

.creator-anchor {
  cursor: pointer;
  text-decoration: underline;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.loading-card {
  position: relative;
  width: 300px;
  height: 300px;
  border: 1px dashed var(--outline);
  border-radius: 20px;
}

.loading-card-parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px;
}

.loading-component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  pointer-events: none;
}

.spinner,
.spinner-shadow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid;
}

.spinner {
  border-color: var(--ink) transparent transparent transparent;
  animation: spinner 1s infinite;
}

.spinner-shadow {
  border-color: rgb(214, 214, 214);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg) translate(-50%, -50%);
  }
  100% {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}

.card-discover {
  position: relative;
  border: 1px solid rgba(0, 0, 42, 0.2);
  border-radius: 30px;
  overflow: hidden;
  width: 320px;
  height: 380px;
  background-color: rgba(255, 255, 255, 40%);
  cursor: pointer;
  transition: all 0.15s var(--quad);
}

.card-discover:hover {
  background-color: rgba(255, 255, 255, 100%);
  box-shadow: 0px 0px 2px -1px rgba(167, 166, 186, 1), 0px 0px 2px 0px rgba(167, 166, 186, 0.4),
    0px 0px 24px -4px rgba(167, 166, 186, 0.6);
}

.card-discover-img {
  position: absolute;
  top: 9px;
  left: 9px;
  width: 300px;
  height: 180px;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
}

.card-discover-img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 300px;
  min-height: 180px;
  transform: translate(-50%, -50%);
}

.card-discover-content {
  position: absolute;
  height: 190px;
  width: 100%;
  top: 190px;
  left: 0;
  margin: 0;
  padding: 0 10px 0 20px;
  color: var(--ink);
}

.card-discover-title,
.card-discover-description {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01px;
  font-family: 'SatoshiMedium', sans-serif;
  margin: 0;
  color: var(--ink);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.card-discover-title {
  max-height: 40px;
  -webkit-line-clamp: 2;
  margin-bottom: 10px;
  margin-top: 20px;
  line-height: 19px;
}
.card-discover-description {
  max-height: 80px;
  font-family: 'SatoshiRegular', sans-serif;
  -webkit-line-clamp: 4;
  line-height: 18px;
}

.card-discover-date {
  position: absolute;
  left: 0;
  bottom: 20px;
  height: 20px;
  font-size: 12px;
  letter-spacing: 0.025em;
  font-family: 'SatoshiRegular', sans-serif;
  padding: 0 10px 0 20px;
  margin: 0;
  color: var(--ink);
}

.nav-overlay {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;
  background-color: var(--frost);
  border-bottom: 1px solid var(--outline);
  box-shadow: 0px 0px 2px -1px rgba(167, 166, 186, 1), 0px 0px 2px 0px rgba(167, 166, 186, 0.4),
    0px 0px 24px -4px rgba(167, 166, 186, 0.6);
}

.nav-overlay-content {
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  max-width: 1000px;
  height: auto;
}

.discover-header {
  position: relative;
  height: 110px;
}

.discover-header > img {
  position: relative;
  top: 20px;
  left: 20px;
  height: 50px;
  cursor: pointer;
  margin-right: 100px;
}

.discover-more,
.discover-signup {
  position: absolute;
  top: 20px;
  font-size: 15px;
  font-family: 'SatoshiBold', sans-serif;
  cursor: pointer;
  height: 50px;
  box-sizing: border-box;
  transition: all 0.15s var(--quad);
}

.discover-more {
  right: 330px;
  border: 1px solid var(--ink);
  background-color: var(--white);
  color: var(--ink);
  border: 0;
  background: transparent;
  padding: 10px 40px;
}

.discover-more > svg {
  position: absolute;
  top: 10px;
  right: 4px;
  width: 30px;
  height: 30px;
  background: transparent;
}

.discover-signup {
  right: 20px;
  max-width: 90%;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  border-radius: 25px;
  padding: 10px min(40px, 5vw);
}

.discover-signup:hover {
  filter: brightness(1.1);
  box-shadow: 0px 0px 2px -1px rgba(167, 166, 186, 1), 0px 0px 2px 0px rgba(167, 166, 186, 0.4),
    0px 0px 24px -4px rgba(167, 166, 186, 0.6);
}

.discover-more:hover > svg {
  filter: brightness(1.1);
}

.discover-title {
  display: inline-block;
  position: relative;
  left: -5px;
  width: 100%;
  margin: 0;
  padding: 0 20px;
  font-size: min(150px, 25vw);
  font-family: 'NeutrafaceBold', sans-serif;
  line-height: 0.8em;
  letter-spacing: -0.05em;
  text-align: center;
  white-space: nowrap;
  color: var(--ink);
}

.discover-title2 {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 20px;
  font-size: min(30px, 5vw);
  font-family: 'SatoshiMedium', sans-serif;
  text-align: center;
  white-space: nowrap;
  color: var(--ink);
}

.search-parent {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 540px;
  max-width: calc(100% - 40px);
  pointer-events: all;
  margin: 20px 0;
}

.search-parent > img {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 20px;
}

.search-form {
  position: relative;
  width: 100%;
  pointer-events: all;
}

.search-form > input {
  width: 100%;
  height: 50px;
  padding: 10px 15px 10px 50px;
  border: 1px solid var(--outline);
  border-radius: calc(2 * var(--font-size));
  box-sizing: border-box;
  font-family: 'SatoshiMedium', sans-serif;
  font-size: 18px;
  letter-spacing: 0.025em;
  background: var(--white);
}

.discover-page {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
}

.discover-page > * {
  font-family: 'SatoshiRegular', sans-serif;
  margin: 10px;
}

.discover-actions {
  position: relative;
  width: 100%;
  padding: 25px 0 15px 0;
  margin: 0;
}

.search-term-parent {
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: relative;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.search-term {
  position: relative;
  height: 40px;
  border: 1px solid #44426d;
  border-radius: 25px;
  background-color: #ffffff;
  color: var(--ink);
  padding: 8px 40px 0px 20px;
  box-sizing: border-box;
  width: fit-content;
  cursor: pointer;
  pointer-events: all;
  font-family: 'SatoshiMedium', sans-serif;
  font-size: 14px;
  letter-spacing: 0.025em;
}

.search-term:hover {
  color: #1f18c0;
  border: 1px solid #1f18c0;
}

.search-term > svg {
  position: absolute;
  height: 20px;
  top: 10px;
  right: 10px;
}

.top-button {
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 40px;
  height: 40px;
  border: 2px solid var(--outline);
  border-radius: 40px;
  background-color: var(--white);
  color: var(--ink);
  cursor: pointer;
  padding: 5px;
  margin: 0;
}

.top-button > img {
  transform: rotate(-90deg);
}

.card-discover-visit-button {
  border-radius: 25px !important;
}

.top-button:hover,
.card-discover-visit-button:hover {
  filter: brightness(0.95);
}

.exhibition-count {
  position: relative;
  bottom: 0;
  left: 30px;
  font-family: 'SatoshiMedium', sans-serif;
  font-size: 18px;
  letter-spacing: 0.025em;
  color: var(--ink);
}

.sortby-visitors-button,
.sortby-date-button {
  position: absolute;
  bottom: 13px;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--ink);
}

.sortby-visitors-button {
  right: 70px;
}

.sortby-date-button {
  right: 30px;
}

.sortby-visitors-button:hover,
.sortby-date-button:hover {
  color: #1f18c0;
}

.disabled {
  color: var(--grey-text);
}

.line {
  fill: none;
  color: currentColor;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.heart-filled {
  fill: var(--red);
  color: var(--red);
  stroke: var(--red);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.path-filled {
  fill: currentColor;
}
