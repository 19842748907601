
@font-face {
  font-family: 'SatoshiRegular';
  font-display: swap;
  src: url('assets/fonts/Satoshi/Satoshi-Regular.woff2') format('woff2'),
    url('assets/fonts/Satoshi/Satoshi-Regular.woff') format('woff'),
    url('assets/fonts/Satoshi/Satoshi-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SatoshiMedium';
  font-display: swap;
  src: url('assets/fonts/Satoshi/Satoshi-Medium.woff2') format('woff2'),
    url('assets/fonts/Satoshi/Satoshi-Medium.woff') format('woff'),
    url('assets/fonts/Satoshi/Satoshi-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SatoshiBold';
  font-display: swap;
  src: url('assets/fonts/Satoshi/Satoshi-Bold.woff2') format('woff2'),
    url('assets/fonts/Satoshi/Satoshi-Bold.woff') format('woff'),
    url('assets/fonts/Satoshi/Satoshi-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeutrafaceBold';
  src: url('assets/fonts/Neutraface/Neutra2Text-Bold.otf');
}

model-preview {
  --progress-bar-color: #04a8e0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--paper-color);
  color: var(--ink-color);
  /* https://www.sitepoint.com/understanding-and-using-rem-units-in-css/ */
  font-size: 62.5%;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'SatoshiRegular', sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.svg-icon {
  fill:none;
  stroke:currentColor;
  stroke-width:2;
  stroke-linecap:round;
  stroke-miterlimit:10;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: 'SatoshiMedium', sans-serif;
}